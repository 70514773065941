

































































































































































































































































































































































































































































































































.human-resources-matrix-table {
  width: 100%;
  text-align: center;
  border-collapse: collapse;

  td {
    padding: 10px;
    border: 1px solid #b4b4b4;
    border-collapse: collapse;
  }

  &-evaluation-name-cell {
    background-color: #e3e3e3;
  }
  &-intersection-name-cell {
    background-color: #f5f5f5;
  }
  &-cards-cell {
    text-align: left;
  }

}
